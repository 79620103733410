.scrollable-div {
    background-color: #f1f1f1;
    height: 350px;
    overflow: auto;
    text-align: justify;
}

@media only screen and (max-width: 800px) {
    .scrollable-div {
        height: 500px;
    }
}
