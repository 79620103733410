.no-more-tables{
    table-layout:fixed;
}

@media only screen and (max-width: 800px) {

    .no-more-tables tbody tr td:nth-child(2){
        width: 100%;
        /*text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;*/
    }

    /* Force table to not be like tables anymore */
    .no-more-tables table,
    .no-more-tables thead,
    .no-more-tables tbody,
    .no-more-tables th,
    .no-more-tables td,
    .no-more-tables tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: right;
    }

    .no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align:left;
        font-weight: bold;
    }

    /*
    Label the data
    */
    .no-more-tables td:before {
        content: attr(data-title);
    }
}
